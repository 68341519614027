import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Features from '@solid-ui-blocks/Features/Block05'
import Footer from '@solid-ui-blocks/Footer/Block01'
import TrustNav from '../../../components/TrustNav/TrustNav'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '../_theme'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const style = `.gatsby-image-wrapper { border: none; }`

  return (
    <Layout theme={theme} {...props}>
      <Seo
        title='Faster Q&A with Feature Flags - Toggly'
        description='Toggly makes it easier for software developers to build better software faster by making it easy to test, release, and measure features without the risk of downtime or other production impacts.'
        keywords={['feature flags, software development']}
        author='opsAI LLC'
        category='Software Development'
        thumbnail='https://toggly.io/cover.png'
        siteUrl='https://toggly.io'
        locale='en_US'
      />

      {/* Blocks */}
      <Header content={content['header-light']} menuJustify='space-between' />

      <Divider space='5' />
      <Divider space='5' />
      <Hero content={content['hero']} reverse />
      <TrustNav content={content['nav']} />
      <Divider space='5' />
      <Features content={content['features']} />
      <Divider space='5' />
      <Footer content={content['footer']} />

      <style>{style}</style>
    </Layout>
  )
}

export const query = graphql`
  query homepageSaasBlockContent {
    allBlockContent(filter: { page: { in: ["homepage/trust/compliance", "homepage/trust", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
